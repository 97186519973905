import { css } from '@emotion/css'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'

export default function AvatarIcon({ currentUser, small }) {
  function getUserIcon(currentUser) {
    let abbrev = ':'
    if (currentUser && currentUser.name) {
      abbrev = currentUser.name.substring(0, 2).toUpperCase()
    }
    return abbrev
  }

  return (
    <ProppedWrapper small={small}>
      {currentUser && currentUser.avatarImageIsUploaded !== null && !currentUser.avatarImageIsUploaded ? (
        <img src={currentUser.avatarImage} />
      ) : (
        <ProppedAvatarIcon small={small}>{getUserIcon(currentUser)}</ProppedAvatarIcon>
      )}
    </ProppedWrapper>
  )
}

AvatarIcon.propTypes = {
  currentUser: PropTypes.object,
}

let sizeWrapper = ({ small }) => {
  if (small) {
    return tw`w-6 h-6`
  } else {
    return tw`w-14 h-14`
  }
}

const ProppedWrapper = styled.div`
  ${sizeWrapper}
`
let style = ({ small }) => {
  if (small) {
    return tw`
    border-2
    border-gray-600
    text-xs
    `
  } else {
    return tw`
    border-4
    border-gray-600    `
  }
}

const StyledAvatarIcon = styled.div(tw`
  w-full
  h-full
  bg-gray-200
  rounded-full
  font-bold
  flex
  justify-center
  items-center
  leading-none
`)

const ProppedAvatarIcon = styled(StyledAvatarIcon)`
  ${style}
`
