import styled from '@emotion/styled'
import { XIcon } from '@heroicons/react/outline'
import useAuth from 'hooks/useAuth'
import Link from 'next/link'
import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'

export default function Overlay({ isOpen, onClose, itemList }) {
  const { logout } = useAuth()

  return (
    <>
      {isOpen && (
        <OverlayWrapper>
          <Nav>
            <CloseBtn onClick={() => onClose()}>Close</CloseBtn>
          </Nav>
          <MenuWrapper>
            <Title>Zalando Festival.</Title>
            <SerifTitle>Connect and Celebrate</SerifTitle>
            <ItemsWrapper>
              {itemList.map((item, i) => (
                <Link key={i} href={item.link}>
                  <Item>{item.title}</Item>
                </Link>
              ))}
            </ItemsWrapper>
            <ItemsWrapper>
              <ItemButton onClick={() => logout()}>Logout</ItemButton>
            </ItemsWrapper>
          </MenuWrapper>
        </OverlayWrapper>
      )}
    </>
  )
}

Overlay.propTypes = {
  itemList: PropTypes.object,
}

const ItemsWrapper = styled.div(tw`
    w-full
    flex
    flex-col
    items-center
    mt-20
`)

const Item = styled.a(tw`
    text-xl
    font-bold
    my-3
`)
const ItemButton = styled.button(tw`
    text-xl
    font-bold
    my-8
`)

const Title = styled.h1(tw`
    text-4xl
    font-semibold
`)
const SerifTitle = styled.h1(tw`
    text-4xl
    font-serif
    text-center
`)
const Nav = styled.div(tw`
    w-full
    h-32
    fixed
`)
const MenuWrapper = styled.div(tw`
    w-full
    flex
    flex-col
    items-center
    mt-32
`)

const CloseBtn = styled(XIcon)(tw`
    float-left
    text-black
    w-12
    mt-6
    ml-8
    cursor-pointer
    hover:text-gray-500
`)

const OverlayWrapper = styled.div(tw`
    w-screen
    h-screen
    bg-white
    z-top
    absolute
`)
