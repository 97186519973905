import styled from '@emotion/styled'
import Loading from 'components/Loading'

export default styled(({ children, ...props }) => (
  <div {...props}>
    <Loading />
    {children || 'Zalando Party App'}
  </div>
))`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;

  ${Loading} {
    margin-right: 0.5em;
  }
`
