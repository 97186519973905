import styled from '@emotion/styled'
import LoadingIcon from 'img/loading.svg'

export default styled(LoadingIcon)`
  width: 1em;
  height: 1em;
  animation: beat 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

  @keyframes beat {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  }
`
