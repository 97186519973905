import { ApolloClient, ApolloProvider, InMemoryCache, gql, useMutation, useQuery } from '@apollo/client'
import { NEXT_PUBLIC_GRAPH_URL } from 'lib/config'
import PropTypes from 'prop-types'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

//
// exported constants
//

export const AuthStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  LOGGED_OUT: 'LOGGED_OUT',
  LOADING: 'LOADING',
  LOGGED_IN: 'LOGGED_IN',
}

//
// provides access to auth state + methods
//

export default function useAuth() {
  return useContext(AuthContext)
}

//
// AuthContextProvider handles user authentication - are we logged in? who are we?
//

function AuthContextProvider({ setGqlAuthToken, children }) {
  const [authStatus, setAuthStatus] = useState(AuthStatus.UNINITIALIZED)

  const currentUserData = useQuery(CURRENT_USER)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const { loading, data, error } = currentUserData
    if (loading) return

    if (error || !data?.currentUser) {
      return setAuthStatus((authStatus) =>
        authStatus === AuthStatus.LOADING ? AuthStatus.LOADING : AuthStatus.LOGGED_OUT
      )
    }

    setCurrentUser(data?.currentUser)
    setAuthStatus(AuthStatus.LOGGED_IN)
  }, [currentUserData])

  const [login] = useMutation(LOGIN)
  const handleLogin = useCallback(
    async (username, password) => {
      setAuthStatus(AuthStatus.LOADING)

      try {
        const loginResult = await login({ variables: { username, password } })
        if (loginResult.error) throw loginResult.error
        if (!loginResult?.data?.logIn) throw 'Please enter a valid username and password'

        setGqlAuthToken(loginResult?.data?.logIn?.token?.jwt)
      } catch (error) {
        setAuthStatus(AuthStatus.LOGGED_OUT)
        if (error.networkError) throw error
        throw 'Please enter a valid username and password'
      }
    },
    [login, setGqlAuthToken]
  )

  const [register] = useMutation(REGISTER)
  const handleRegister = useCallback(
    async (username, password) => {
      setAuthStatus(AuthStatus.LOADING)

      try {
        const registerResult = await register({ variables: { username, password } })
        if (registerResult.error) throw registerResult.error
        if (!registerResult?.data?.registerUser) throw 'Please enter a valid username and password'

        setGqlAuthToken(registerResult?.data?.registerUser?.token?.jwt)
      } catch (error) {
        setAuthStatus(AuthStatus.LOGGED_OUT)
        if (error.networkError) throw error
        throw 'Please enter a valid username and password'
      }
    },
    [register, setGqlAuthToken]
  )

  const logout = useCallback(() => {
    setGqlAuthToken('')
    setAuthStatus(AuthStatus.LOGGED_OUT)
  }, [setGqlAuthToken])

  const value = useMemo(() => ({ authStatus, currentUser, handleLogin, handleRegister, logout }), [
    authStatus,
    currentUser,
    handleLogin,
    handleRegister,
    logout,
  ])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
AuthContextProvider.propTypes = { setGqlAuthToken: PropTypes.func.isRequired, children: PropTypes.any }

//
// AuthProvider joins ApolloProvider and AuthContextProvider
//

export function AuthProvider({ children }) {
  const [gqlAuthToken, _setGqlAuthToken] = useState(localStorage.getItem(localStorageGqlAuthTokenKey))
  const setGqlAuthToken = useCallback((gqlAuthToken) => {
    localStorage.setItem(localStorageGqlAuthTokenKey, gqlAuthToken)
    _setGqlAuthToken(gqlAuthToken)
  }, [])

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({
          typePolicies: {
            KaraokePerformance: {
              merge: false,
            },
          },
        }),
        headers: gqlAuthToken ? { authorization: `Bearer ${gqlAuthToken}` } : undefined,
        uri: NEXT_PUBLIC_GRAPH_URL,
        connectToDevTools: true,
      }),
    [gqlAuthToken]
  )

  return (
    <ApolloProvider client={client}>
      <AuthContextProvider gqlAuthToken={gqlAuthToken} setGqlAuthToken={setGqlAuthToken}>
        {children}
      </AuthContextProvider>
    </ApolloProvider>
  )
}
AuthProvider.propTypes = { children: PropTypes.any }

//
// constants
//

const AuthContext = createContext({})
const localStorageGqlAuthTokenKey = 'zld-gql-jwt'

//
// graphql
//

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    logIn(userName: $username, password: $password) {
      token {
        jwt
      }
    }
  }
`
export const REGISTER = gql`
  mutation register($username: String!, $password: String!) {
    registerUser(userName: $username, password: $password) {
      token {
        jwt
      }
    }
  }
`
export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      name
      avatarImage
      avatarImageIsUploaded
    }
  }
`
