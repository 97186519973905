import LoadingFullscreen from 'components/LoadingFullscreen'
import useAuth, { AuthStatus } from 'hooks/useAuth'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { useEffect } from 'react'

export default function RedirectUnauthed({ to = '/login', children }) {
  const { authStatus } = useAuth()
  const router = useRouter()

  useEffect(() => {
    authStatus === AuthStatus.LOGGED_OUT && router.push(to)
  }, [authStatus, router, to])

  if (authStatus === AuthStatus.UNINITIALIZED) return <LoadingFullscreen />
  if (authStatus === AuthStatus.LOGGED_OUT) return null
  return children
}
RedirectUnauthed.propTypes = { to: PropTypes.string }
