import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'

export default function Footer({ className }) {
  return (
    <FooterStyle className={className}>
      <FooterText>
        <b>Zalando Festival.</b> <Serif>Connect and Celebrate.</Serif>
      </FooterText>
    </FooterStyle>
  )
}
Footer.propTypes = {
  className: PropTypes.string,
}

const FooterStyle = styled.div(tw`
    w-full
    h-60
    flex
    justify-center
    items-center
    relative
    bottom-0
    bg-zalando-dark
    mt-40
    pl-12
    pr-12
`)

const FooterText = styled.p(tw`
    text-4xl
    text-white
    `)

const Serif = styled.span(tw`
    font-serif
    `)
