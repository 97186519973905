export const isDevelopment = process.env.NODE_ENV === 'development'

export const UPLOAD_PROTOCOL = process.env.UPLOAD_PROTOCOL
export const UPLOAD_HOST = process.env.UPLOAD_HOST
export const UPLOAD_PORT = process.env.UPLOAD_PORT

export const S3_USE_SSL = String(process.env.S3_USE_SSL).toLowerCase() === 'true'
export const S3_HOST = process.env.S3_HOST
export const S3_PORT = parseInt(process.env.S3_PORT, 10)
export const S3_ACCESS_KEY = process.env.S3_ACCESS_KEY
export const S3_SECRET_KEY = process.env.S3_SECRET_KEY
export const S3_BUCKET = process.env.S3_BUCKET

export const KMS_PROTOCOL = process.env.KMS_PROTOCOL
export const KMS_HOST = process.env.KMS_HOST
export const KMS_PORT = process.env.KMS_PORT
export const KMS_PATH = process.env.KMS_PATH

export const NEXT_PUBLIC_MQTT_URL = process.env.NEXT_PUBLIC_MQTT_URL

export const NEXT_PUBLIC_TURN_IP = process.env.NEXT_PUBLIC_TURN_IP
export const NEXT_PUBLIC_TURN_USERNAME = process.env.NEXT_PUBLIC_TURN_USERNAME
export const NEXT_PUBLIC_TURN_PASSWORD = process.env.NEXT_PUBLIC_TURN_PASSWORD

export const NEXT_PUBLIC_PARTICIPANT_PASSWORD = process.env.NEXT_PUBLIC_PARTICIPANT_PASSWORD

export const NEXT_PUBLIC_IMAGEKIT_URL = process.env.NEXT_PUBLIC_IMAGEKIT_URL

export const NEXT_PUBLIC_LOGIN_CODE = process.env.NEXT_PUBLIC_LOGIN_CODE

export const NEXT_PUBLIC_GRAPH_URL = process.env.NEXT_PUBLIC_GRAPH_URL

export default {
  isDevelopment,

  UPLOAD_PROTOCOL,
  UPLOAD_HOST,
  UPLOAD_PORT,

  S3_USE_SSL,
  S3_HOST,
  S3_PORT,
  S3_ACCESS_KEY,
  S3_SECRET_KEY,
  S3_BUCKET,

  KMS_PROTOCOL,
  KMS_HOST,
  KMS_PORT,
  KMS_PATH,

  NEXT_PUBLIC_MQTT_URL,

  NEXT_PUBLIC_TURN_IP,
  NEXT_PUBLIC_TURN_USERNAME,
  NEXT_PUBLIC_TURN_PASSWORD,

  NEXT_PUBLIC_PARTICIPANT_PASSWORD,

  NEXT_PUBLIC_IMAGEKIT_URL,

  NEXT_PUBLIC_LOGIN_CODE,

  NEXT_PUBLIC_GRAPH_URL,
}
