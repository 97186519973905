import styled from '@emotion/styled'
import ZalandoLogoSvg from 'img/zalando.svg'
import React from 'react'

export default function ZalandoLogo({ ...passProps }) {
  return <StyledZalangoLogoSvg {...passProps} />
}

const StyledZalangoLogoSvg = styled(ZalandoLogoSvg)`
  display: block;

  height: 35px;
`
