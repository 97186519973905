import styled from '@emotion/styled'
import { XIcon } from '@heroicons/react/outline'
import useAuth from 'hooks/useAuth'
import Link from 'next/link'
import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'

export default function TopNavItem({ itemList }) {
  const { logout } = useAuth()

  return (
    <>
      {itemList.map((item, i) => (
        <Link key={i} href={item.link}>
          <Item>{item.title}</Item>
        </Link>
      ))}
    </>
  )
}

TopNavItem.propTypes = {
  itemList: PropTypes.object,
}

const Item = styled.a(tw`
  block
  mx-4
  my-2
`)
