import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MenuIcon } from '@heroicons/react/outline'
import AvatarIcon from 'components/AvatarIcon'
import Overlay from 'components/Overlay'
import TopNavItem from 'components/TopNavItem'
import ZalandoLogo from 'components/ZalandoLogo'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import React from 'react'
import tw from 'twin.macro'

export default function TopNav() {
  const { currentUser, logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  const itemList = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: "Let's sing",
      link: '/karaoke',
    },
    {
      title: "Let's dance",
      link: '/dance',
    },
    {
      title: 'Hall of Fame',
      link: '/gallery',
    },
    {
      title: "Let's play",
      link: '/games',
    },
    {
      title: 'Live Stream',
      link: '/livestream',
    },
    {
      title: 'Zalando TV',
      link: '/videos',
    },
    {
      title: 'VR Experience',
      link: '/vr-experience',
    },
  ]

  return (
    <>
      <Overlay isOpen={isOpen} onClose={() => setIsOpen(false)} itemList={itemList} />
      <StyledTopNav>
        <NavSection>
          <div>
            <HamburgerMenu onClick={() => setIsOpen(!isOpen)} />
          </div>
          <div>
            <StyledZalandoLogo alt="Zalando Logo" />
          </div>
          <div>
            <AvatarIcon currentUser={currentUser} />
          </div>
        </NavSection>
        <Menue>
          <TopNavItem itemList={itemList} />
        </Menue>
      </StyledTopNav>
    </>
  )
}
const Menue = styled.div(tw`
  hidden
  lg:flex
  lg:flex-row
  lg:flex-nowrap
  lg:justify-center
`)
const StyledTopNav = styled.header(tw`
  border-b-2
  mb-0
  pb-6
  lg:pb-1
`)

const NavSection = styled.div(tw`
  flex
  flex-row
  flex-nowrap
  justify-between
  items-center
  mx-8
  mt-4
`)

const StyledZalandoLogo = styled(ZalandoLogo)`
  width: 9rem;
  max-width: 100%;
`

const HamburgerMenu = styled(MenuIcon)(tw`
  w-12
  h-12
  p-1
  text-black
  cursor-pointer
  bg-white
  block
  lg:hidden
`)
