import { css } from '@emotion/css'
import styled from '@emotion/styled'
import LottieSing from 'components/lotties/sing'
import PropTypes from 'prop-types'
import React from 'react'
import Lottie from 'react-lottie'
import tw from 'twin.macro'

export default function ModuleHeader({ bgColor, color, subtitle, title, text, imgUrl, lottie }) {
  const LottieSingOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieSing,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const LottieTurnTableOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieSing,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <StyledModuleHeader>
      <Container
        className={css`
          background-color: ${bgColor};
        `}
      >
        <MaxWidth>
          <Flex>
            <TextContainer>
              <Title
                className={css`
                  color: ${color};
                `}
              >
                {title}
              </Title>
              <SubTitle
                className={css`
                  color: ${color};
                `}
              >
                {subtitle}
              </SubTitle>
              <TitleText
                className={css`
                  color: ${color};
                `}
              >
                {text}
              </TitleText>
            </TextContainer>
            {imgUrl && !lottie && (
              <ImageWrapper>
                <ImageBack>
                  <Image src={imgUrl} />
                </ImageBack>
              </ImageWrapper>
            )}
            {lottie && (
              <ImageWrapper>
                <ImageBack>
                  {lottie === 'LottieSing' && <Lottie options={LottieSingOptions} height="20rem" />}
                  {lottie === 'LottieTurnTable' && <Lottie options={LottieTurnTableOptions} height="20rem" />}
                </ImageBack>
              </ImageWrapper>
            )}
          </Flex>
        </MaxWidth>
      </Container>
    </StyledModuleHeader>
  )
}

ModuleHeader.propTypes = {
  absolute: PropTypes.bool,
  backUrl: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

const Container = styled.div(tw`
  w-full
  flex
  justify-center
`)

const Flex = styled.div(tw`
  flex
`)

const MaxWidth = styled.div(tw`
  w-full max-w-7xl px-12 xl:px-0
`)

const TextContainer = styled.div(tw`
  my-16 w-full
`)

const StyledModuleHeader = styled.div(tw`
  flex
  justify-center
  items-center
  flex-col
  w-full
`)

const Title = styled.h1(tw`
  font-bold
  text-3xl
`)

const SubTitle = styled.h1(tw`
 text-3xl
 font-serif
 mt-2
`)

const TitleText = styled.h1(tw`
  text-2xl
  font-serif
  mt-4
  pr-24
`)

const ImageWrapper = styled.div(tw`
  hidden lg:block pt-12

`)

const ImageBack = styled.div(tw`
  bg-zalando-turquese-300
  relative
`)

const Image = styled.img(tw`
  relative
`)
